import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './boost-your-coding-fu-with-vscode-and-vim.module.css'
import Layout from '../vscodevimbook/Layout'
import SEO from '../components/seo'
import AgnosticLink from '../components/AgnosticLink'
import Button from '../components/Button'
import { StaticImage } from 'gatsby-plugin-image'
import {HeroText} from '../vscodevimbook/components/hero-text/hero-text'

export default class BoostCodingFuVSCodeVim extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <article className={styles.page}>
          {/* TODO(vintharas): Why isn't this providing all the necessary data? */}
          <SEO
            date={new Date(2019, 3, 24)}
            dateModified={new Date(2022, 1, 1)}
            title={`Boost Your Coding Fu With VSCode and Vim - The Book | ${siteTitle}`}
          />
          <header className={styles.header}>
            <HeaderBody />
          </header>
          <section className={styles.content}>
            <section className={styles.book}>
              <h2>The Book</h2>
              <section>
                <AgnosticLink
                  href={'/boost-your-coding-fu-with-vscode-and-vim/dedication'}
                >
                  <StaticImage
                    src="../../static/images/boost-your-coding-fu-with-vscode-and-vim-3d-book-cover-no-shadow.png"
                    alt="Boost Your Coding Fu with VSCode and Vim Cover"
                    title="Boost Your Coding Fu with VSCode and Vim"
                    width={300}
                    height={473}
                    quality={100}
                    className={styles.image}
                    layout="fixed"
                  />
                </AgnosticLink>
                <p>
                  A practical guide to learning Vim in VSCode and becoming a
                  more productive developer.
                </p>
              </section>
              <footer className={styles.bookactions}>
                <Button
                  pixelated
                  blue
                  to={'/boost-your-coding-fu-with-vscode-and-vim/dedication'}
                >
                  Read Free Online
                </Button>

                <Button
                  pixelated
                  pink
                  to={'https://leanpub.com/boostyourcodingfuwithvscodeandvim'}
                  target="_blank"
                >
                  Buy
                </Button>
                <Button
                  pixelated
                  darkgreen
                  to={'/boost-your-coding-fu-with-vscode-and-vim-exercises'}
                >
                  Practice
                </Button>
              </footer>
            </section>
            <section className={styles.plugin}>
              <h2>The Plugin</h2>
              <section>
                <StaticImage
                  src="../../static/images/learn-vim-icon.png"
                  alt="Learn Vim extension icon"
                  title="Learn Vim right within VSCode"
                  width={512}
                  height={512}
                  quality={100}
                  className={styles.image}
                />
                <p>
                  Learn Vim is a Visual Studio extension that helps you learn
                  Vim right within Visual Studio Code.
                </p>
              </section>
              <footer className={styles.bookactions}>
                <Button
                  pixelated
                  darkgreen
                  to={
                    'https://marketplace.visualstudio.com/items?itemName=vintharas.learn-vim'
                  }
                >
                  Learn Vim
                </Button>
              </footer>
            </section>
            <section className={styles.videos}>
              <h2>The Videos</h2>
              <section>
                <Videos />
                <p>
                  A collection of short and concise videos to help you master
                  Vim step by step.
                </p>
                <p>
                  From the very basics on how to install Vim in VSCode, to how
                  to move lightning fast across your editor and code at the
                  speed of lightning.
                </p>
              </section>
              <footer className={styles.bookactions}>
                <Button
                  pixelated
                  darkblue
                  to={
                    'https://www.youtube.com/watch?list=PLWkmatwYW3nE0bhFmV3zxtqHGTowomCRY&v=zQ6Ua-8pM9g&feature=emb_title'
                  }
                >
                  Watch
                </Button>
              </footer>
            </section>
            <section className={styles.podcast}>
              <h2>The Podcast</h2>
              <section>
                <StaticImage
                  src="../../static/images/boost-your-coding-fu-square-banner.jpg"
                  alt="Boost Your Coding Fu With VSCode and Vim - The Auditory Experience"
                  title="Boost Your Coding Fu With VSCode and Vim - The Auditory Experience"
                  width={1000}
                  height={1000}
                  quality={100}
                  className={styles.image}
                />
                <p>
                  Learn Vim while cleaning the dishes or doing the laundry. Or
                  fall asleep while listening to my incredibly soothing voice.
                </p>
                <p>
                  An auditory experience where I narrate Boost Your Coding Fu
                  With VSCode and Vim.
                </p>
              </section>
              <footer className={styles.bookactions}>
                <Button
                  pixelated
                  darkgreen
                  to={'/boost-your-coding-fu-with-vscode-and-vim-podcast'}
                >
                  Listen
                </Button>
              </footer>
            </section>
          </section>
        </article>
      </Layout>
    )
  }
}

const HeaderBody = () => (
  <section className={styles.headerbody}>
    <HeroText />
    <IntroVideo />
    <p>
      <strong>Visual Studio Code is superb</strong>. It offers an unparalleled
      user experience with great support for many languages and development
      ecosystems. It comes with great defaults and is super easy to use and to
      get started with.
    </p>
    <p>
      <strong>Vim is awesome</strong>. Its modal nature and text editing
      features make it unique amongst other editors. Vim offers a complete
      different level of text editing proficiency, speed and accuracy from
      anything else out there.
    </p>
    <p>
      The combination of both couldn't be anything less that{' '}
      <strong>amazingly superbsome</strong> (yep, I just did that).
    </p>
    <p>
      In the upcoming days, and as you read these pages and practice, you will
      boost your Visual Studio editing skills incredibly. You'll become better
      by bringing in lots of useful tools from the Vim editor right inside the
      comfort of your favorite editor: <strong>Visual Studio Code</strong>.
    </p>
  </section>
)

const IntroVideo = () => (
  <section className={styles.videoplayer}>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/zQ6Ua-8pM9g"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </section>
)

const Videos = () => (
  <section className={styles.videoplayer}>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/videoseries?list=PLWkmatwYW3nE0bhFmV3zxtqHGTowomCRY"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </section>
)

export const pageQuery = graphql`
  query BoostCodingFuVSCodeVimQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          book: { eq: "Boost Your Coding Fu With VSCode and Vim" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date
            date_modified
            title
            categories
            chapter
          }
          timeToRead
        }
      }
    }
  }
`
